<template>
  <div>
    <en-table-layout :tableData="tableData.data" :loading="loading" :tips="true">
      <!-- 搜索条件 -->
      <div slot="tips" class="TitleBox">
        <div class="conditions">
          <div>搜索种类：</div>
          <el-select class="choose-machine" v-model="params.keyword" placeholder="请选择状态" clearable size="mini">
            <el-option v-for="item in searchList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="conditions" v-if="params.keyword !== ''">
          <div>输入搜索：</div>
          <el-input v-model="params.keyvalue"
            :placeholder="params.keyword === '活动名称' ? '请输入活动名称' : params.keyword === '活动编号' ? '请输入活动编号' : params.keyword === '所属商户' ? '请输入商户名称' : '请选择搜索种类'"
            class="ipt-default" style="width:210px;" size="mini"></el-input>
        </div>
        <div class="conditions">
          <div>活动分类：</div>
          <el-cascader style="width:69%;" expand-trigger="hover" :options="typeList" @change="activityTypeChange"
            :props="{ checkStrictly: true }" :clearable="true" size="mini"></el-cascader>
        </div>
        <div class="conditions">
          <div>活动状态：</div>
          <el-select v-model="params.market_enable" :clearable="true" size="mini">
            <el-option value="0" label="已下架">已下架</el-option>
            <el-option value="1" label="上架中">上架中</el-option>
          </el-select>
        </div>
        <div class="conditions" style="margin-left:20px;">
          <div>活动开始日期：</div>
          <el-date-picker style="width:260px;margin-right:20px;" v-model="times" type="daterange" align="center"
            :editable="false" unlink-panels size="mini" range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期" value-format="timestamp" @change="timesChange"></el-date-picker>
        </div>
        <div class="conditions">
          <el-button @click="searchActivityList" type="primary" class="btn-default" size="mini">筛选</el-button>
        </div>
      </div>
      <template slot="table-columns">
        <!--  活动名称 -->
        <el-table-column label="活动名称" width="225" fixed="left">
          <template slot-scope="scope">
            <div class="imgBox">
              <img :src="scope.row.thumbnail" />
              <div :title="scope.row.goods_name">{{ scope.row.goods_name }}</div>
            </div>
          </template>
        </el-table-column>
        <!-- 所属商户名称 -->
        <el-table-column prop="seller_name" label="所属商户名称" />
        <!--  活动编号 -->
        <el-table-column prop="sn" label="活动编号" />
        <!--  活动一级分类 -->
        <el-table-column prop="category_first" label="活动一级分类" />
        <!--  活动二级分类 -->
        <el-table-column prop="category_second" label="活动二级分类" />
        <!--原价-->
        <el-table-column label="原价">
          <template slot-scope="scope">
            <div>{{ scope.row.mkt_low ? scope.row.mkt_low : 0 }}元{{ scope.row.mkt_low === scope.row.mkt_high ? '' : '起'
              }}
            </div>
          </template>
        </el-table-column>
        <!--销售价-->
        <el-table-column label="销售价">
          <template slot-scope="scope">
            <div>{{ scope.row.price_low }}元{{ scope.row.price_low === scope.row.price_top ? '' : '起' }}</div>
          </template>
        </el-table-column>
        <!--累计销量-->
        <el-table-column prop="buy_count" label="累计销量" />
        <!--名额限制-->
        <el-table-column prop="sale_limit_num" label="名额限制" />
        <!--剩余名额-->
        <el-table-column prop="enable_quantity" label="剩余名额" />
        <!--申请上架时间-->
        <el-table-column prop="apply_time" :formatter="MixinUnixToDate" label="申请上架时间" width="160" />
        <!--审核时间-->
        <el-table-column prop="auth_time" :formatter="MixinUnixToDate" label="审核时间" width="160" />
        <!--更新时间-->
        <!-- <el-table-column prop="update_date" :formatter="MixinUnixToDate" label="更新时间"/> -->
        <!--活动开始时间-->
        <el-table-column prop="min_time" :formatter="MixinUnixToDate" label="活动开始时间" width="160" />
        <!--活动结束时间-->
        <el-table-column prop="max_time" :formatter="MixinUnixToDate" label="活动结束时间" width="160" />
        <!--  活动链接地址 -->
        <el-table-column prop="act_url" label="活动链接地址" />
        <el-table-column label="活动状态">
          <template slot-scope="scope">
            {{ scope.row.market_enable === 0 ? "已下架" : "上架中" }}
          </template>
        </el-table-column>
        <!--  操作 -->
        <el-table-column label="操作" width="130" fixed="right">
          <template slot-scope="scope">
            <div class="buttonBox">
              <!-- <el-button @click="openDialog('copy', scope.row)" type="text" size="mini" style="margin-bottom:5px;">复制链接
              </el-button> -->
              <el-button @click="openDialog('down', scope.row)" type="text" size="mini"
                style="margin-bottom:5px;margin-left:0;color:#fe5558;" v-if="scope.row.market_enable === 1">下架
              </el-button>
              <el-button @click="openDialog('details', scope.row)" type="text" size="mini"
                style="margin-bottom:5px;margin-left:0;">查看详情
              </el-button>
            </div>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="tableData.data" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size" layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>
    <el-dialog :title="dialogTitle" :visible.sync="centerDialogVisible" width="30%" center>
      <div class="dialogContent" v-if="dialogTitle === '复制链接成功'">{{ dialogContent }}</div>
      <div class="dialogContent2" v-if="dialogTitle === '活动下架确认'">
        <div class="left">
          <div>下架原因：</div>
        </div>
        <div class="right">
          <el-input class="textarea" type="textarea" :rows="3" placeholder="请填写下架原因" v-model="downParams.reason"
            resize="none"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false" v-if="dialogTitle == '活动下架确认'">取 消</el-button>
        <el-button type="primary" @click="ok">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as API_activityType from '@/api/activityType';
import * as API_activityList from '@/api/activityList';

export default {
  name: 'activityList',
  data () {
    return {
      //  列表loading状态
      loading: false,
      //  列表参数
      params: {
        page_no: 1,
        page_size: 10,
        keyword: '',
        category_first: null,
        category_second: null,
        active_start: '',
        active_end: '',
      },

      //  列表数据
      // 0：待审核，1：审核通过，2：审核拒绝，3:审核中
      tableData: {},
      // 弹框显示内容
      dialogTitle: '',
      dialogContent: '',
      centerDialogVisible: false,

      // 搜索种类
      searchList: ['活动名称', '活动编号', '所属商户名称'],

      // 活动分类
      typeList: [],

      // 活动开始时间
      times: [],

      // 选中的列表行
      chooseRow: '',

      downParams: {
        reason: '',
      },
    };
  },
  mounted () {
    // 获取活动分类列表
    this.GET_activeTypeGet();

    /** 获取活动列表 */
    this.POST_activeGoodsActivityPage2();
  },
  activated () {
    // 获取活动分类列表
    this.GET_activeTypeGet();

    /** 获取活动列表 */
    this.POST_activeGoodsActivityPage2();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.POST_activeGoodsActivityPage2();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.POST_activeGoodsActivityPage2();
    },

    /** 搜索活动列表 */
    searchActivityList () {
      this.params.page_no = 1
      this.POST_activeGoodsActivityPage2()
    },

    /** 获取活动列表 */
    POST_activeGoodsActivityPage2 () {
      API_activityList.activeGoodsActivityPage2(this.params).then((res) => {
        this.tableData = res;
        console.log(res);
      });
    },

    /**  获取活动分类列表 */
    GET_activeTypeGet () {
      API_activityType.activeTypeGet(0, {}).then((res) => {
        this.typeList = [];
        res.forEach((item1, index1) => {
          var jsonParent = {};
          jsonParent.value = item1.category_id;
          jsonParent.label = item1.name;
          var children = [];
          if (item1.children && item1.children.length > 0) {
            item1.children.forEach((item2, index2) => {
              var jsonChild = {};
              jsonChild.value = item2.category_id;
              jsonChild.label = item2.name;
              children.push(jsonChild);
            });
            jsonParent.children = children;
          }
          this.typeList.push(jsonParent);
        });
      });
    },

    /**  下架商品 */
    PUT_activeTypeGet () {
      if (this.downParams.reason === '') {
        this.$message.error('请填写下架原因！');
        return false;
      }
      this.centerDialogVisible = false;
      API_activityList.activeGoodsActivityUnder(
        this.chooseRow.goods_id,
        this.downParams
      ).then((res) => {
        console.log(res);
        this.$message.success('下架成功！');
        this.chooseRow = '';
        this.downParams.reason = '';
        this.POST_activeGoodsActivityPage2();
      });
    },

    /**  选择活动分类 */
    activityTypeChange (val) {
      this.params.category_first = null;
      this.params.category_second = null;
      this.typeList.forEach((item1) => {
        if (item1.value === val[0]) {
          this.params.category_first = item1.value;
          if (item1.children && item1.children.length > 0) {
            item1.children.forEach((item2) => {
              if (item2.value === val[1]) {
                this.params.category_second = item2.value;
              }
            });
          }
        }
      });
    },

    /**  选择查询时间 */
    timesChange () {
      console.log(this.times);
      if (this.times) {
        if (this.times[0] === this.times[1]) {
          this.params.active_start = this.times[0] / 1000;
          this.params.active_end = this.times[1] / 1000 + 86399;
        } else {
          this.params.active_start = this.times[0] / 1000;
          this.params.active_end = this.times[1] / 1000;
        }
      } else {
        this.params.active_start = '';
        this.params.active_end = '';
      }
    },

    /** 打开弹窗或页面跳转 */
    openDialog (type, row) {
      this.chooseRow = row;
      if (type === 'copy') {
        console.log(row);
        this.dialogTitle = '复制链接成功';
        this.dialogContent = '复制成功，点击确定返回';
      } else if (type === 'down') {
        this.dialogTitle = '活动下架确认';
        this.dialogContent = '下架后将不允许报名参与，确认下架吗？';
      } else if (type === 'details') {
        this.$router.push({
          name: '活动详情',
          params: { id: row.goods_id },
        });
        return false;
      }
      this.centerDialogVisible = true;
    },

    /** 弹窗点击确定 */
    ok () {
      if (this.dialogTitle === '活动下架确认') {
        this.PUT_activeTypeGet();
      }
      this.centerDialogVisible = false;
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
::v-deep .el-table td:not(.is-left) {
  text-align: center;
}

::v-deep .toolbar {
  padding: 0 !important;
}

.TitleBox {
  display: flex;
  flex-wrap: wrap;

  .conditions {
    display: flex;
    align-items: center;

    div {
      font-size: 15px;
      font-weight: 500;
      color: rgba(68, 68, 68, 1);
    }

    margin-bottom: 20px;

    .choose-machine {
      width: 60%;
    }
  }
}

.imgBox {
  display: flex;
  align-items: center;

  img {
    width: 64px;
    height: 48px;
    margin-right: 10px;
  }

  div {
    width: 150px;
    font-size: 16px;
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.buttonBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialogContent {
  font-size: 16px;
  font-weight: 500;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}

.dialogContent2 {
  width: 100%;
  display: flex;

  .left {
    width: 100px;
    text-align: right;

    div {
      font-size: 16px;
      line-height: 16px;
    }
  }

  .right {
    flex: 1;
  }
}
</style>
